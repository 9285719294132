exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-nations-index-js": () => import("./../../../src/pages/nations/index.js" /* webpackChunkName: "component---src-pages-nations-index-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-templates-book-cover-template-js": () => import("./../../../src/templates/bookCoverTemplate.js" /* webpackChunkName: "component---src-templates-book-cover-template-js" */),
  "component---src-templates-book-template-js": () => import("./../../../src/templates/bookTemplate.js" /* webpackChunkName: "component---src-templates-book-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-diary-template-js": () => import("./../../../src/templates/diaryTemplate.js" /* webpackChunkName: "component---src-templates-diary-template-js" */),
  "component---src-templates-nation-template-js": () => import("./../../../src/templates/nationTemplate.js" /* webpackChunkName: "component---src-templates-nation-template-js" */)
}

